import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'

import Layout from '../components/Layout'
import { Container } from '../components/Grid'

const Div = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`

const Page = ({ data }) => {
  const { menu, markets, ...account } = data.root.account
  const { markdownRemark: md } = data
  return <Layout
    account={account}
    page={{ title: md.frontmatter.title }}
    menu={menu}
    markets={markets.items}
  >
    <Container>
      <Div>
        <Markdown source={md.rawMarkdownBody} />
      </Div>
    </Container>
  </Layout>
}

export const query = graphql`
  query getMarkdownPageData($account: ID $slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      rawMarkdownBody
      frontmatter {
        title
      }
    }
    root {
     account: getAccountById(id: $account) {
        name
        title
        googleSiteVerification
        menu {
          title
          href
          subMenu {
            title
            subTitle
            href
          }
        }

        markets(sort: [["market", "1"]]) {
          count
          items {
            title: market
            state {
              name
            }
            marketPage {
              slug
            }
            apartments(filter: { status:published } sort: [["name", "1"]]) {
              items {
                name
                marketingWebsiteUrl
              }
            }
          }
        }
      }
    }
  }
`

export default Page
